import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export function useIsMobile(): boolean {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return isMobile;
}

export function useIsTablet(): boolean {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  return isTablet;
}

export function useIsMiniScreen(): boolean {
  const theme = useTheme();
  const isMiniScreen = useMediaQuery(theme.breakpoints.down('lg'));
  return isMiniScreen;
}

export function useDownXl(): boolean {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('xl'));
}

import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './en/translation.json';
import zhHK from './zh-Hant-HK/translation.json';
import zh from './zh/translation.json';
import { convertLanguageJsonToObject } from './translations';

export const translationsJson = {
  en: {
    translation: en,
  },
  'zh-Hant-HK': {
    translation: zhHK,
  },
  zh: {
    translation: zh,
  },
};

// Create the 'translations' object to provide full intellisense support for the static json files.
convertLanguageJsonToObject('zh-Hant-HK');

export const i18n = i18next
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: 'zh-Hant-HK',
    resources: translationsJson,
    fallbackLng: 'en',
    debug:
      process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test',

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export const supportedLanguages = ['en', 'zh-Hant-HK', 'zh'];
export type SupportedLanguage = 'en' | 'zh-Hant-HK' | 'zh';

export function languageToLabel(
  language: SupportedLanguage,
  isAbbreviated: boolean = false,
) {
  switch (language) {
    case 'en':
      return isAbbreviated ? 'EN' : 'English';
    case 'zh-Hant-HK':
      return isAbbreviated ? '繁中' : '繁體中文';
    case 'zh':
      return isAbbreviated ? '简中' : '简体中文';
  }
}

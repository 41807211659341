import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Advisor } from 'types';
import {
  MediaConfig,
  MediaUploadConfigParams,
  SubmitReferral,
  SubmitReferralResponse,
  TeamLogoNews,
  UpdateAdviserProfileResponse,
  UploadImageResponse,
  UploadMediaParams,
  User,
} from './apiTypes';
import { getUploadMediaFormData } from './utils';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: apiBaseUrl,
    credentials: 'include',
  }),
  tagTypes: ['Advisor'],
  endpoints: builder => ({
    authenticateUser: builder.query<User, void>({
      query: () => ({
        url: 'auth',
        method: 'POST',
      }),
    }),
    getAdviserProfilePlusById: builder.query<Advisor | null, string>({
      query: id => `adviser-profile-plus/${id}`,
      providesTags: ['Advisor'],
    }),
    getAdviserTeamLogoNews: builder.query<TeamLogoNews, void>({
      query: () => `getUserTeamLogoNews`,
    }),
    likeAdviserProfilePlusById: builder.mutation<Advisor | null, string>({
      query: id => `adviser-profile-plus/like/${id}`,
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData('getAdviserProfilePlusById', id, advisor => {
            if (advisor) {
              Object.assign(advisor, {
                likes: advisor.likes + 1,
              });
            }
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    submitAdvisorReferral: builder.mutation<
      SubmitReferralResponse,
      SubmitReferral
    >({
      query: body => ({
        url: 'submitReferral',
        method: 'POST',
        body,
      }),
    }),
    // Other endpoints
    uploadImage: builder.mutation<UploadImageResponse, FormData>({
      query: body => ({
        url: 'uploadImage',
        method: 'POST',
        body,
      }),
    }),
    getMediaUploadConfig: builder.query<MediaConfig, MediaUploadConfigParams>({
      query: params => ({
        url:
          'media/upload-config?' +
          new URLSearchParams({
            type: params.type,
            resource_id: params.resourceId,
            content_type: params.contentType,
            content_length: params.contentLength,
            filename: params.filename,
          }),
      }),
    }),
    uploadMedia: builder.mutation<void, UploadMediaParams>({
      query: uploadMediaParams => ({
        url: uploadMediaParams.mediaConfig.url,
        method: 'POST',
        body: getUploadMediaFormData(uploadMediaParams),
      }),
    }),
    updateAdviserProfile: builder.mutation<UpdateAdviserProfileResponse, any>({
      query: body => ({
        url: 'updateUserDetails',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Advisor'],
    }),
  }),
});

export const {
  useAuthenticateUserQuery,
  useGetAdviserProfilePlusByIdQuery,
  useLikeAdviserProfilePlusByIdMutation,
  useSubmitAdvisorReferralMutation,
  useGetAdviserTeamLogoNewsQuery,
  useUploadImageMutation,
  useUpdateAdviserProfileMutation,
  useUploadMediaMutation,
  useLazyGetMediaUploadConfigQuery,
} = api;

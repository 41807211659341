import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from 'react-router-dom';

import { NotFoundPage } from './components/NotFoundPage/Loadable';
import { useTranslation } from 'react-i18next';
import { getThemeOptions } from 'styles/theme';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useMemo } from 'react';
import { ColorModeProvider, useColorMode } from 'hooks/useColorMode';
import { CssBaseline } from '@mui/material';
import { DASHBOARD_ROUTES, PUBLIC_ROUTES } from './routes';
import ErrorBoundary from './components/ErrorBoundary';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DashboardMenu } from './components/DashboardMenu';

export const ColorModeContext = React.createContext({
  toggleColorMode: () => {},
});

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      {/* Dashboard routes */}
      <Route element={<DashboardMenu />}>
        {DASHBOARD_ROUTES.map(({ path, element }) => (
          <Route key={path} path={path} element={element} />
        ))}
      </Route>
      {/* Public routes routes */}
      {PUBLIC_ROUTES.map(({ path, element }) => (
        <Route key={path} path={path} element={element} />
      ))}
      <Route path="*" element={<NotFoundPage />} />
    </Route>,
  ),
);

export function App() {
  return (
    <ColorModeProvider>
      <WrappedApp />
    </ColorModeProvider>
  );
}

export function WrappedApp() {
  const { t, i18n } = useTranslation();
  const { colorMode } = useColorMode();

  const theme = useMemo(
    () => createTheme(getThemeOptions(colorMode)),
    [colorMode],
  );

  return (
    <ErrorBoundary>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Helmet
            titleTemplate="%s - PortfoPlus"
            defaultTitle="PortfoPlus"
            htmlAttributes={{ lang: i18n.language }}
          >
            <meta name="description" content={t('Meta.Description')} />
          </Helmet>
          <RouterProvider router={router}></RouterProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </ErrorBoundary>
  );
}

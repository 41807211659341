import { EditProfilePage } from './pages/EditProfilePage/Loadable';
import { AdvisorProfilePage } from './pages/AdvisorProfilePage/Loadable';
import { RouteProps as ReactRouterRouteProps } from 'react-router-dom';
import { ProfilePlusPage } from './pages/ProfilePlusPage/Loadable';

type Route = {
  isMenuCollapsable?: boolean;
  showTitle?: boolean;
  titlei18nKey?: string;
} & ReactRouterRouteProps;

export const PUBLIC_ROUTES: Route[] = [
  {
    path: 'profile-plus',
    element: <ProfilePlusPage />,
  },
  {
    path: 'adviser/:adviserId',
    element: <AdvisorProfilePage />,
  },
];

export const DASHBOARD_ROUTES: Route[] = [
  {
    path: '/my-details',
    index: true,
    element: <EditProfilePage />,
    isMenuCollapsable: true,
    showTitle: true,
    titlei18nKey: 'EditProfilePage.Title',
  },
];

// Only include public adviser routes
export const ROUTES = [...PUBLIC_ROUTES, ...DASHBOARD_ROUTES];

export default ROUTES;

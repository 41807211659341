import clsx from 'clsx';
import styles from './Portfoplus.module.css';

export default function Portfoplus({
  href,
  classNames,
}: {
  href?: string;
  classNames?: {
    container?: string;
    title?: string;
  };
}) {
  const Logo = () => (
    <div className={clsx(styles.container, classNames?.container)}>
      <img
        alt="portfoplus-logo"
        className={styles.image}
        src="/images/logo-portfoplus.png"
      />
    </div>
  );

  if (href) {
    return (
      <a href={href}>
        <Logo />
      </a>
    );
  }

  return <Logo />;
}

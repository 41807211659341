import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';
import { PaletteMode } from '@mui/material';

export type ColorModeContextType = {
  setColorMode: Dispatch<SetStateAction<PaletteMode>>;
  colorMode: PaletteMode;
};
export const ColorModeContext = createContext<ColorModeContextType>({
  setColorMode: () => {},
  colorMode: 'light',
});

export const ColorModeProvider = ({ children }) => {
  const [colorMode, setColorMode] = useState<PaletteMode>('light');
  const value = useMemo(() => ({ colorMode, setColorMode }), [colorMode]);
  return (
    <ColorModeContext.Provider value={value}>
      {children}
    </ColorModeContext.Provider>
  );
};

export const useColorMode = () => {
  const { colorMode, setColorMode } =
    useContext<ColorModeContextType>(ColorModeContext);

  return { colorMode, setColorMode };
};

import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  IconButton,
  Badge,
  Avatar,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Fade,
} from '@mui/material';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import {
  Home as HomeIcon,
  Search as SearchIcon,
  Work as WorkIcon,
  PeopleAlt as PeopleAltIcon,
  Face as FaceIcon,
  HelpOutline as HelpOutlineIcon,
  Language as LanguageIcon,
  Logout as LogoutIcon,
  NotificationsNone as NotificationsNoneIcon,
  Menu as MenuIcon,
} from '@mui/icons-material';
import styles from './DashboardMenu.module.css';
import { useIsMiniScreen } from 'hooks/useBreakpoints';
import { useTranslation } from 'react-i18next';
import Portfoplus from './Portfoplus';
import VSpace from './VSpace';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Routes from 'app/routes';
import React, { useState } from 'react';

type MenuItem = {
  icon: React.ReactElement;
  title: string;
  isActive?: boolean;
  onClick?: () => void;
};

export function DashboardMenu() {
  const { t, i18n } = useTranslation();
  const isMiniScreen = useIsMiniScreen();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const currentRoute = Routes.find(route => route.path === pathname);
  const isCollapsable = currentRoute?.isMenuCollapsable || false;
  const showTitle = currentRoute?.showTitle || false;

  const [isMenuCollapsed, setIsMenuCollapsed] = useState<boolean>(false);
  const handleCollapseMenu = () => {
    setIsMenuCollapsed(!isMenuCollapsed);
  };
  const isCompactView = isMiniScreen || isMenuCollapsed;

  const topMenuItems: Array<MenuItem> = [
    {
      icon: <HomeIcon />,
      title: t('DashboardMenu.Home'),
      isActive: ['/', '/home'].includes(pathname),
      onClick: () => navigate('/home'),
    },
    {
      icon: <SearchIcon />,
      title: t('DashboardMenu.BigData'),
      isActive: ['/big_data'].includes(pathname),
      onClick: () => navigate('/big_data'),
    },
    { icon: <WorkIcon />, title: t('DashboardMenu.Business') },
    {
      icon: <PeopleAltIcon />,
      title: t('DashboardMenu.Client'),
      isActive: [
        '/clients',
        '/add-clients',
        '/create-sample',
        '/add-portfolio',
      ].includes(pathname),
      onClick: () => navigate('/clients'),
    },
    { icon: <FaceIcon />, title: t('DashboardMenu.Account') },
  ];

  const midMenuItems: Array<MenuItem> = [
    { icon: <NotificationsNoneIcon />, title: t('DashboardMenu.Notification') },
    { icon: <HelpOutlineIcon />, title: t('DashboardMenu.HelpCenter') },
  ];

  const bottomMenuItems: Array<MenuItem> = [
    {
      icon: <LanguageIcon />,
      title: t('Language'),
      onClick: () =>
        i18n.changeLanguage(i18n.language === 'en' ? 'zh-Hant-HK' : 'en'),
    },
    { icon: <LogoutIcon />, title: t('DashboardMenu.Logout') },
  ];

  // REVIEW: see if we got a better way to do this
  const mobileMenuItems: Array<MenuItem> = [
    topMenuItems[1],
    topMenuItems[2],
    topMenuItems[0],
    topMenuItems[3],
    topMenuItems[4],
  ];

  const currentMenuTitle = topMenuItems.find(item => item.isActive)?.title;

  const MenuItem = ({ icon, title, onClick, isActive }: MenuItem) => {
    return isCompactView ? (
      <Fade in={isCompactView}>
        <IconButton color={isActive ? 'primary' : undefined} onClick={onClick}>
          {icon}
        </IconButton>
      </Fade>
    ) : (
      <Fade in={!isCompactView}>
        <ListItem
          classes={{
            root: isActive ? styles.menuItemActive : undefined,
          }}
          disablePadding
        >
          <ListItemButton onClick={onClick}>
            <ListItemIcon
              className={isActive ? styles.menuItemIconActive : undefined}
            >
              {icon}
            </ListItemIcon>
            <ListItemText primary={title} />
          </ListItemButton>
        </ListItem>
      </Fade>
    );
  };

  const menu = (
    <div className={styles.menuContainer}>
      <Portfoplus
        classNames={{
          container: isCompactView ? styles.compactMenuLogo : styles.menuLogo,
        }}
      />
      <VSpace size={4} />
      <List className={styles.topList}>
        {topMenuItems.map((menuItem, index) => (
          <MenuItem {...menuItem} key={index} />
        ))}
        <VSpace size={2} />
        {midMenuItems.map((menuItem, index) => (
          <MenuItem {...menuItem} key={index} />
        ))}
      </List>
      <List className={styles.bottomList}>
        {bottomMenuItems.map((menuItem, index) => (
          <MenuItem {...menuItem} key={index} />
        ))}
        <VSpace size={1} />
      </List>
    </div>
  );

  return (
    <Box className={styles.mainContainer}>
      <Box
        className={isCompactView ? styles.compactMenuBox : styles.menuBox}
        component="nav"
        sx={{
          transition: '.3s all',
        }}
      >
        <Drawer
          anchor="left"
          className={isCompactView ? styles.compactDrawer : styles.drawer}
          variant="permanent"
          open
        >
          {menu}
        </Drawer>
      </Box>
      <Box className={styles.mainBox} component="main">
        <AppBar position="static" elevation={0} color="inherit">
          <Toolbar className={styles.appToolBar}>
            {isCollapsable && (
              <IconButton size="large" onClick={handleCollapseMenu}>
                <MenuIcon />
              </IconButton>
            )}
            {showTitle && currentRoute?.titlei18nKey && (
              <Typography variant="h5">
                {t(currentRoute.titlei18nKey)}
              </Typography>
            )}
            <Typography
              variant="h5"
              color="white"
              sx={{ display: { xs: 'flex', md: 'none' } }}
            >
              LOGO
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Box className={styles.iconsContainer}>
              <IconButton size="large" color="inherit">
                <HelpOutlineOutlinedIcon />
              </IconButton>
              <IconButton size="large" color="inherit">
                <Badge badgeContent={9} color="error">
                  <NotificationsNoneIcon />
                </Badge>
              </IconButton>
              <IconButton
                size="large"
                edge="end"
                color="inherit"
                sx={{ display: { xs: 'none', md: 'inline-block' } }}
              >
                <Avatar src="/images/default-avatar.png" />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        <Box className={styles.contentBox}>
          <Outlet />
        </Box>
        <Paper className={styles.bottomNavigation} elevation={3}>
          <BottomNavigation showLabels value={currentMenuTitle}>
            {mobileMenuItems.map((menuItem, index) => (
              <BottomNavigationAction
                key={index}
                label={menuItem.title}
                icon={menuItem.icon}
                value={menuItem.title}
                onClick={menuItem.onClick}
              />
            ))}
          </BottomNavigation>
        </Paper>
      </Box>
    </Box>
  );
}

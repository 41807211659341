import { UploadMediaParams } from './apiTypes';
import { Buffer } from 'buffer';

export const getUploadMediaFormData = ({
  mediaConfig,
  file,
}: UploadMediaParams) => {
  const formData = new FormData();
  formData.append(
    'policy',
    Buffer.from(JSON.stringify(mediaConfig.policy)).toString('base64'),
  );
  formData.append('key', mediaConfig.policy.conditions[1]['key']);
  formData.append(
    'Content-Type',
    mediaConfig.policy.conditions[2]['Content-Type'],
  );
  formData.append(
    'x-amz-algorithm',
    mediaConfig.policy.conditions[4]['x-amz-algorithm'],
  );
  formData.append(
    'x-amz-credential',
    mediaConfig.policy.conditions[5]['x-amz-credential'],
  );
  formData.append('x-amz-date', mediaConfig.policy.conditions[6]['x-amz-date']);
  formData.append('x-amz-signature', mediaConfig.signature);
  formData.append('file', file);
  return formData;
};

export const getDownloadUrl = (key: string) => {
  return (
    `${process.env.REACT_APP_API_BASE_URL}/media/download-url?` +
    new URLSearchParams({
      object_path: key,
    })
  );
};
